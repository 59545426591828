import './App.css';
import SignIn from './Components/Account/SingnIn';
import Dashboard from './Components/Dashboard/Dashboard';
import { BrowserRouter,Navigate, Outlet, Routes, Route, Link , useLocation , useNavigate   } from 'react-router-dom';
import React,{useEffect,useState,useRef} from 'react';
import Profiles from './Components/Account/Profiles';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import axios from "axios";
import {SERVER_URL} from './Config';

const url = window.location.href;
const mdTheme = createTheme();

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['appToken']);
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };


  return (
    <div className="App">
        <Routes>
          <Route path="/login" element={<SigInRoute><SignLayout /></SigInRoute>} />
          <Route path="/" element={<PrivateRoute><MainLayout /></PrivateRoute>}>
            <Route path="profile" element={<Profiles />} />
          </Route>
        </Routes>
    </div>
  );
}

export default App;




function SignLayout() {

  return (
    <div className='AuthContainer'>
      <SignIn />
    </div>
  )
}

function MainLayout() {

  return (
    <Dashboard />
  )
}

function PrivateRoute({children}) {
  debugger
  const [cookies, setCookie, removeCookie] = useCookies(['appToken']);
  
  return (
      <>
          {isAuthenticated(cookies) ? children : <Navigate to="/login" replace/>}
      </>
  );
}

function SigInRoute({children}) {
  const [checkCookie, setCheckCookie] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['appToken']);
    useEffect(() => {
      async function fetchData() {
        debugger
        let url = "admin/account/tokenCheck";
        axios({
          method: "Get",
          url: SERVER_URL + url,
          headers: {
            Authorization: "Bearer " + cookies.appToken,
          },
        })
          .then((response) => {
  
            return response;
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              localStorage.setItem("check" , false);
            }
            else{
              localStorage.setItem("check" , true);
         
              }
          })
      }
      fetchData();
    
    }, []);
    return (
        <>
            {isAuthenticated(cookies) && localStorage.getItem("check") === "true"  ? <Navigate to="/login" replace/> : children}
        </>
    );
}

const isAuthenticated = (cookies) => {
  debugger
  let token = cookies.appToken;
  if (!!token) {
      return true
  } else {
      return false
  }
}