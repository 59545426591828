import { React, useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import { dynamicGetApi, dynamicPostApi } from './../../Api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const addValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter firstName"),
  lastName: Yup.string().required("Please enter lastName"),
  profile_Email: Yup.string().email("Invalid email address").required("Please enter email"),
  password: Yup.string().required("Please enter Password"),
  cpassword: Yup.string().required("This field is required").oneOf([Yup.ref('password'), null], 'Passwords must match')
});
// const editValidationSchema = Yup.object().shape({
//   firstName: Yup.string().required("Please enter email"),
//   lastName: Yup.string().required("Please enter email"),
//   profile_Email: Yup.string().email("Invalid email address").required("Please enter email")
// });
export default function AddeditProfileModal(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['appToken']);
  let navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedHour, setSelectedHour] = useState();
  const [selectedMints, setSelectedMints] = useState();
  const [profileEmail, setProfileEmail] = useState('');
  const [emailCheckValid, setEmailCheckValid] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: 0,
      salutation: "",
      firstName: "",
      lastName: "",
      profile_Email: "",
      password: "",
      cpassword: "",
      name: "",
      address1: "",
      address2: "",
      postCode: "",
      city: "",
      phone: "",
      email: "",
      website: "",
      logo: "",
      blockingPassword: false,
      bugReports: false,
      BugReportTime: new Date(),
      sendAnalyticsData: false,
      allowPriviousEntry: false,
      navigateTo: "",
      dgsvo_data_protection_officer_name: "",
      dgsvo_data_protection_officer_address: "",
      dgsvo_data_protection_officer_contact: "",
      dgsvo_factoring_provide: "",
      dangerZonePassword: "",
      ip: "",
      verificationToken:""
    },
    //validationSchema: props.editMode === true ? editValidationSchema : addValidationSchema,
    validationSchema: addValidationSchema,
    onSubmit: async values => {
      delete values['cpassword'];
      console.log(values);
      let url = 'external/profile/updateProfile';
      let resdata = await dynamicPostApi(values, url, navigate, setCookie);
      Swal.fire(
        'Saved!',
        'Record has been save successfully.',
        'success'
      )
      handleClose();
    },
  });

  useEffect(() => {
    async function fetchData() {
      let url = 'external/profile/getProfileByUserId?id=' + props.selectId;
      let resdata = await dynamicGetApi(url, navigate, setCookie);
      if (!!resdata) {
        formik.setFieldValue("id", resdata.data.id);
        formik.setFieldValue("salutation", resdata.data.salutation);
        formik.setFieldValue("firstName", resdata.data.firstName);
        formik.setFieldValue("lastName", resdata.data.lastName);
        formik.setFieldValue("profile_Email", resdata.data.profile_Email);
        formik.setFieldValue("password", resdata.data.password);
        formik.setFieldValue("cpassword", resdata.data.password);
        formik.setFieldValue("name", resdata.data.name);
        formik.setFieldValue("address1", resdata.data.address1);
        formik.setFieldValue("address2", resdata.data.address2);
        formik.setFieldValue("postCode", resdata.data.postCode);
        formik.setFieldValue("city", resdata.data.city);
        formik.setFieldValue("phone", resdata.data.phone);
        formik.setFieldValue("email", resdata.data.email);
        formik.setFieldValue("website", resdata.data.website);
        formik.setFieldValue("logo", resdata.data.logo);
        formik.setFieldValue("blockingPassword", resdata.data.blockingPassword);
        formik.setFieldValue("bugReports", resdata.data.bugReports);
        formik.setFieldValue("bugReportTime", resdata.data.bugReportTime);
        formik.setFieldValue("sendAnalyticsData", resdata.data.sendAnalyticsData);
        formik.setFieldValue("allowPriviousEntry", resdata.data.allowPriviousEntry);
        formik.setFieldValue("navigateTo", resdata.data.navigateTo);
        formik.setFieldValue("dgsvo_data_protection_officer_name", resdata.data.dgsvo_data_protection_officer_name);
        formik.setFieldValue("dgsvo_data_protection_officer_address", resdata.data.dgsvo_data_protection_officer_address);
        formik.setFieldValue("dgsvo_data_protection_officer_contact", resdata.data.dgsvo_data_protection_officer_contact);
        formik.setFieldValue("dgsvo_factoring_provide", resdata.data.dgsvo_factoring_provide);
        formik.setFieldValue("dangerZonePassword", resdata.data.dangerZonePassword);
        formik.setFieldValue("ip", resdata.data.ip);
        formik.setFieldValue("verificationToken", resdata.data.verificationToken);
        setSelectedDate(new Date(resdata.data.bugReportTime).getDate());
        setSelectedMonth(new Date(resdata.data.bugReportTime).getMonth() + 1);
        setSelectedYear(new Date(resdata.data.bugReportTime).getFullYear());
        setSelectedHour(new Date(resdata.data.bugReportTime).getHours());
        setSelectedMints(new Date(resdata.data.bugReportTime).getMinutes());
        setProfileEmail(resdata.data.profile_Email);
      }
    }
    if (props.editMode === true) {
      fetchData();
    }
  }, [props.show]);

  const handleClose = () => {
    props.setShow(false);
    props.setRecallList(true);
    if (props.editMode === true) {
      props.setEditMode(false);
    }
  };

  const handleChange = (e) => {
    let val = e.target.value;
    setProfileEmail(val);
  }

  const emailCheck = async (e) => {
    debugger
    let val = e.target.value;
    if (!props.editMode || (val !== "" && formik.values.profile_Email !== profileEmail)) {
      let url = 'external/profile/emailExistCheck?email=' + val;
      let result = await dynamicGetApi(url, navigate, setCookie);
      if (result.data) {
        setEmailCheckValid(true);
      }
      else {
        setEmailCheckValid(false);
        formik.setFieldValue('profile_Email', val);
      }

    }
  }

  const [Tabvalue, setTabvalue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabvalue(newValue);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.show}
        maxWidth={"md"}
        fullWidth={"md"}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.editMode === true ? "Edit" : "Add"} Profile
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form className='add-profile-form'>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={Tabvalue} onChange={handleTabChange} aria-label="basic tabs example" className='add-profile-tabs'>
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="Praxis" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={Tabvalue} index={0}>
                <div style={{ marginTop: '20px' }}>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <TextField
                        label="Anrede"
                        id="outlined-basic"
                        value={formik.values.salutation}
                        onChange={formik.handleChange}
                        style={{ width: "100%" }}
                        variant="outlined"
                        name="salutation"
                        size="small" />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="firstName"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        size="small"
                        label="Vorname"
                        id="outlined-basic"
                        variant="outlined"
                        required
                        error={formik.errors.firstName && formik.touched.firstName ? true : false}
                        helperText={formik.errors.firstName && formik.touched.firstName ? formik.errors.firstName : ""} />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        size="small"
                        label="Nachname"
                        id="outlined-basic"
                        variant="outlined"
                        required
                        error={formik.errors.lastName && formik.touched.lastName ? true : false}
                        helperText={formik.errors.lastName && formik.touched.lastName ? formik.errors.lastName : ""} />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="profile_Email"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => emailCheck(e)}
                        value={profileEmail}
                        size="small"
                        label="Email"
                        id="outlined-basic"
                        variant="outlined"
                        required
                        //InputProps={props.editMode === false ? {readOnly: false} : {readOnly: true}}
                        error={formik.errors.profile_Email && formik.touched.profile_Email ? true : false}
                        helperText={formik.errors.profile_Email && formik.touched.profile_Email ? formik.errors.profile_Email : ""} />
                      {emailCheckValid ? <label style={{ color: "red", 'font-size': "12px" }}>Email already exist</label> : null}
                    </Grid>
                    {/* {props.editMode === false ? */}
                    <>
                      <Grid item md={6}>
                        <TextField
                          style={{ width: "100%" }}
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          size="small"
                          label="Passwort"
                          id="outlined-basic"
                          variant="outlined"
                          type={"password"}
                          required
                          error={formik.errors.password && formik.touched.password ? true : false}
                          helperText={formik.errors.password && formik.touched.password ? formik.errors.password : ""} />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          style={{ width: "100%" }}
                          name="cpassword"
                          onChange={formik.handleChange}
                          value={formik.values.cpassword}
                          size="small"
                          label="Bestatigen Passwort"
                          id="outlined-basic"
                          variant="outlined"
                          type={"password"}
                          error={formik.errors.cpassword && formik.touched.cpassword ? true : false}
                          helperText={formik.errors.cpassword && formik.touched.cpassword ? formik.errors.cpassword : ""} />
                      </Grid>
                    </>
                    {/* : null */}
                    {/* } */}
                  </Grid>
                </div>
              </TabPanel>
              <TabPanel value={Tabvalue} index={1}>
                <div style={{ marginTop: '20px' }}>
                  <Grid container spacing={3}>
                    <Grid item md={4}>
                      <input type='file' className='inpunt-border-none' />
                    </Grid>
                    <Grid item md={8}>
                      <img src={formik.values.logo} style={{ height: "100px" }} />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        size="small"
                        label="Name der Praxis"
                        id="outlined-basic"
                        variant="outlined" />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="address1"
                        onChange={formik.handleChange}
                        value={formik.values.address1}
                        size="small"
                        label="Adresszelie 1"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="address2"
                        onChange={formik.handleChange}
                        value={formik.values.address2}
                        size="small"
                        label="Adresszelie 2"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="postCode"
                        onChange={formik.handleChange}
                        value={formik.values.postCode}
                        size="small"
                        label="Postleitzahl"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="city"
                        onChange={formik.handleChange}
                        value={formik.values.city}
                        size="small"
                        label="Stadt"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        size="small"
                        label="Telefon"
                        id="outlined-basic"
                        variant="outlined"
                      // InputProps={props.editMode === false ? {readOnly: false} : {readOnly: true}}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        size="small"
                        label="Email"
                        id="outlined-basic"
                        variant="outlined"
                        // InputProps={props.editMode === false ? {readOnly: false} : {readOnly: true}}
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="website"
                        onChange={formik.handleChange}
                        value={formik.values.website}
                        size="small"
                        label="Website"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="verificationToken"
                        onChange={formik.handleChange}
                        value={formik.values.verificationToken}
                        size="small"
                        label="Verification Token"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="ip"
                        onChange={formik.handleChange}
                        value={formik.values.ip}
                        size="small"
                        label="Local IP Address"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <div>
                        <h3>Optionen (praxisweite Einstellungen)</h3>
                        <div className='practice-settings'>
                          <div className='d-flex-between'>
                            <div><span className='fs-16px'>Sperrpasswort für Aufklärungsbögen</span></div>
                            <div>
                              <Checkbox name='blockingPassword' checked={formik.values.blockingPassword} onChange={formik.handleChange} />
                              {/* <FormControlLabel control={<Checkbox />} label="Label" /> */}
                            </div>
                          </div>
                        </div>
                        <div className='practice-settings'>
                          <div className='d-flex-between'>
                            <div><span className='fs-16px'>Zustimmung zum Senden von Fehlerberichten</span></div>
                            <div>
                              <Checkbox name='bugReports' checked={formik.values.bugReports} onChange={formik.handleChange} />
                            </div>
                          </div>
                          <div>
                            <p className='m-0px fs-13px'>
                              Fehlerberichte enthalten technische Informationen, mit denen wir etwaige Abstürze nachvollziehen können. Für das Erheben und Übermitteln der Fehlerberichte nutzen wir den Dienst "App Center" von Microsoft. Im Rahmen der Fehlerberichte werden Informationen über Ihr iPad (z.B. eindeutige Gerätekennung, Modellversion, Version des Betriebssystems) sowie Ihre Athena-Benutzerkennung übermittelt. Zusätzlich wird Ihre IP-Adresse von Microsoft temporär gespeichert, um einen ordnungsgemäßen Betrieb des Dienstes zu gewährleisten.
                            </p>
                          </div>
                          <div>
                            <Grid className='mt-5px' container spacing={3}>
                              <Grid item md={3}>
                                <label className='m-0px'>am</label>
                              </Grid>
                              <Grid item md={9}>
                                <div className='d-flex-between'>

                                  <FormControl sx={{ m: 1, minWidth: 95 }} size="small" disabled>
                                    <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedDate}
                                      label="Age"
                                    // onChange={handleChange}
                                    >
                                      <MenuItem value={1}>1</MenuItem>
                                      <MenuItem value={2}>2</MenuItem>
                                      <MenuItem value={3}>3</MenuItem>
                                      <MenuItem value={4}>4</MenuItem>
                                      <MenuItem value={5}>5</MenuItem>
                                      <MenuItem value={6}>6</MenuItem>
                                      <MenuItem value={7}>7</MenuItem>
                                      <MenuItem value={8}>8</MenuItem>
                                      <MenuItem value={9}>9</MenuItem>
                                      <MenuItem value={10}>10</MenuItem>
                                      <MenuItem value={12}>12</MenuItem>
                                      <MenuItem value={13}>13</MenuItem>
                                      <MenuItem value={14}>14</MenuItem>
                                      <MenuItem value={15}>15</MenuItem>
                                      <MenuItem value={16}>16</MenuItem>
                                      <MenuItem value={17}>17</MenuItem>
                                      <MenuItem value={18}>18</MenuItem>
                                      <MenuItem value={19}>19</MenuItem>
                                      <MenuItem value={20}>20</MenuItem>
                                      <MenuItem value={21}>21</MenuItem>
                                      <MenuItem value={22}>22</MenuItem>
                                      <MenuItem value={23}>23</MenuItem>
                                      <MenuItem value={24}>24</MenuItem>
                                      <MenuItem value={25}>25</MenuItem>
                                      <MenuItem value={26}>26</MenuItem>
                                      <MenuItem value={27}>27</MenuItem>
                                      <MenuItem value={28}>28</MenuItem>
                                      <MenuItem value={29}>29</MenuItem>
                                      <MenuItem value={30}>30</MenuItem>
                                      <MenuItem value={31}>31</MenuItem>
                                    </Select>
                                  </FormControl>

                                  <FormControl sx={{ m: 1, minWidth: 95 }} size="small" disabled>
                                    <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedMonth}
                                      label="Age"
                                    // onChange={handleChange}
                                    >
                                      <MenuItem value={1}>Januar</MenuItem>
                                      <MenuItem value={2}>Februar</MenuItem>
                                      <MenuItem value={3}>März</MenuItem>
                                      <MenuItem value={4}>April</MenuItem>
                                      <MenuItem value={5}>Mai</MenuItem>
                                      <MenuItem value={6}>Juni</MenuItem>
                                      <MenuItem value={7}>Juli</MenuItem>
                                      <MenuItem value={9}>August</MenuItem>
                                      <MenuItem value={9}>September</MenuItem>
                                      <MenuItem value={10}>Oktober</MenuItem>
                                      <MenuItem value={11}>November</MenuItem>
                                      <MenuItem value={12}>Dezember</MenuItem>
                                    </Select>
                                  </FormControl>

                                  <FormControl sx={{ m: 1, minWidth: 95 }} size="small" disabled>
                                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedYear}
                                      label="Age"
                                    // onChange={handleChange}
                                    >
                                      <MenuItem value={2016}>2016</MenuItem>
                                      <MenuItem value={2017}>2017</MenuItem>
                                      <MenuItem value={2018}>2018</MenuItem>
                                      <MenuItem value={2019}>2019</MenuItem>
                                      <MenuItem value={2020}>2020</MenuItem>
                                      <MenuItem value={2021}>2021</MenuItem>
                                      <MenuItem value={2022}>2022</MenuItem>
                                      <MenuItem value={2023}>2023</MenuItem>
                                      <MenuItem value={2024}>2024</MenuItem>
                                      <MenuItem value={2025}>2025</MenuItem>
                                      <MenuItem value={2026}>2026</MenuItem>
                                    </Select>
                                  </FormControl>
                                  <span className='symb' style={{ marginBottom: '17px' }}>_</span>
                                  <FormControl sx={{ m: 1, minWidth: 95 }} size="small" disabled>
                                    <InputLabel id="demo-simple-select-label">Hour</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedHour}
                                      label="Age"
                                    // onChange={handleChange}
                                    >
                                      <MenuItem value="00">0</MenuItem>
                                      <MenuItem value="01">1</MenuItem>
                                      <MenuItem value="02">2</MenuItem>
                                      <MenuItem value="03">3</MenuItem>
                                      <MenuItem value="04">4</MenuItem>
                                      <MenuItem value="05">5</MenuItem>
                                      <MenuItem value="06">6</MenuItem>
                                      <MenuItem value="07">7</MenuItem>
                                      <MenuItem value="08">8</MenuItem>
                                      <MenuItem value="09">9</MenuItem>
                                      <MenuItem value="10">10</MenuItem>
                                      <MenuItem value="11">11</MenuItem>
                                      <MenuItem value="12">12</MenuItem>
                                      <MenuItem value="13">13</MenuItem>
                                      <MenuItem value="14">14</MenuItem>
                                      <MenuItem value="15">15</MenuItem>
                                      <MenuItem value="16">16</MenuItem>
                                      <MenuItem value="17">17</MenuItem>
                                      <MenuItem value="18">18</MenuItem>
                                      <MenuItem value="19">19</MenuItem>
                                      <MenuItem value="20">20</MenuItem>
                                      <MenuItem value="21">21</MenuItem>
                                      <MenuItem value="22">22</MenuItem>
                                      <MenuItem value="23">23</MenuItem>
                                    </Select>
                                  </FormControl>
                                  <span className='symb'>:</span>
                                  <FormControl sx={{ m: 1, minWidth: 95 }} size="small" disabled>
                                    <InputLabel id="demo-simple-select-label">Minutes</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedMints}
                                      label="Age"
                                    // onChange={handleChange}
                                    >
                                      <MenuItem value="00">0</MenuItem>
                                      <MenuItem value="01">1</MenuItem>
                                      <MenuItem value="02">2</MenuItem>
                                      <MenuItem value="03">3</MenuItem>
                                      <MenuItem value="04">4</MenuItem>
                                      <MenuItem value="05">5</MenuItem>
                                      <MenuItem value="06">6</MenuItem>
                                      <MenuItem value="07">7</MenuItem>
                                      <MenuItem value="08">8</MenuItem>
                                      <MenuItem value="09">9</MenuItem>
                                      <MenuItem value="10">10</MenuItem>
                                      <MenuItem value="11">11</MenuItem>
                                      <MenuItem value="12">12</MenuItem>
                                      <MenuItem value="13">13</MenuItem>
                                      <MenuItem value="14">14</MenuItem>
                                      <MenuItem value="15">15</MenuItem>
                                      <MenuItem value="16">16</MenuItem>
                                      <MenuItem value="17">17</MenuItem>
                                      <MenuItem value="18">18</MenuItem>
                                      <MenuItem value="19">19</MenuItem>
                                      <MenuItem value="20">20</MenuItem>
                                      <MenuItem value="21">21</MenuItem>
                                      <MenuItem value="22">22</MenuItem>
                                      <MenuItem value="23">23</MenuItem>
                                      <MenuItem value="24">24</MenuItem>
                                      <MenuItem value="25">25</MenuItem>
                                      <MenuItem value="26">26</MenuItem>
                                      <MenuItem value="27">27</MenuItem>
                                      <MenuItem value="28">28</MenuItem>
                                      <MenuItem value="29">29</MenuItem>
                                      <MenuItem value="30">302</MenuItem>
                                      <MenuItem value="31">31</MenuItem>
                                      <MenuItem value="32">32</MenuItem>
                                      <MenuItem value="33">33</MenuItem>
                                      <MenuItem value="34">34</MenuItem>
                                      <MenuItem value="35">35</MenuItem>
                                      <MenuItem value="36">36</MenuItem>
                                      <MenuItem value="37">37</MenuItem>
                                      <MenuItem value="38">38</MenuItem>
                                      <MenuItem value="39">39</MenuItem>
                                      <MenuItem value="40">40</MenuItem>
                                      <MenuItem value="41">41</MenuItem>
                                      <MenuItem value="42">42</MenuItem>
                                      <MenuItem value="43">43</MenuItem>
                                      <MenuItem value="44">44</MenuItem>
                                      <MenuItem value="45">45</MenuItem>
                                      <MenuItem value="46">46</MenuItem>
                                      <MenuItem value="47">47</MenuItem>
                                      <MenuItem value="48">48</MenuItem>
                                      <MenuItem value="49">49</MenuItem>
                                      <MenuItem value="50">50</MenuItem>
                                      <MenuItem value="51">51</MenuItem>
                                      <MenuItem value="52">52</MenuItem>
                                      <MenuItem value="53">53</MenuItem>
                                      <MenuItem value="54">54</MenuItem>
                                      <MenuItem value="55">55</MenuItem>
                                      <MenuItem value="56">56</MenuItem>
                                      <MenuItem value="57">57</MenuItem>
                                      <MenuItem value="58">58</MenuItem>
                                      <MenuItem value="59">59</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                        <div className='practice-settings'>
                          <div className='d-flex-between'>
                            <div><span className='fs-16px'>Zustimmung zum Senden von Analysedaten</span></div>
                            <div>
                              <Checkbox name='sendAnalyticsData' checked={formik.values.sendAnalyticsData} onChange={formik.handleChange} />
                            </div>
                          </div>
                          <div>
                            <p className='m-0px fs-13px'>
                              Die Analysedaten umfassen Informationen über Ihr iPad (z.B. Modellversion und Version des Betriebssystems) und zum Nutzungsverhalten. Letzteres umfasst die Häufigkeit der Nutzung sowie bestimmte Ereignisse, z.B. "Beratungsprotokoll erstellt". Die erfassten Daten werden pseudonymisiert erfasst und können von uns nicht Ihrem Benutzerkonto zugeordnet werden. Auch hier wird Ihre IP-Adresse von Microsoft temporär gespeichert, um einen ordnungsgemäßen Betrieb des Dienstes zu gewährleisten.
                            </p>
                            <p className='m-0px fs-13px'>
                              Weitere Informationen zum Datenschutz finden Sie in unserer Datenschutzerklärung.
                            </p>
                            <div><span className='fs-16px'>Bis jetzt keine Zustimmung gegeben</span></div>
                          </div>
                        </div>
                        <div className='practice-settings'>
                          <div className='d-flex-between'>
                            <div><span className='fs-16px'>Laden von früheren Eingaben erlauben</span></div>
                            <div>
                              <Checkbox name='allowPriviousEntry' checked={formik.values.allowPriviousEntry} onChange={formik.handleChange} />
                            </div>
                          </div>
                          <div>
                            <p className='m-0px fs-13px'>
                              Wenn diese Einstellung aktiviert ist, können Patienten beim erneuten Ausfüllen desselben Anamnesebogens ihre früheren Angaben in allen Feldern vorausfüllen lassen.
                            </p>
                          </div>
                        </div>
                        <div className='practice-settings radio-btn'>
                          <Grid container spacing={0}>
                            <Grid item md={8}>
                              <div><span className='fs-16px'>Nach dem Speichern eines Anamnesebogens navigieren zurück zur</span></div>
                              <div><span className='fs-13px'>Diese Einstellung bestimmt, welche Seite nach dem Entsperren der App angezeigt wird.</span></div>
                            </Grid>
                            <Grid item md={4}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel name='navigateTo' checked={formik.values.navigateTo === 'patientlist' ? true : false} onChange={formik.handleChange} value="patientlist" control={<Radio />} label="Liste mit allen Patienten" />
                                <FormControlLabel name='navigateTo' checked={formik.values.navigateTo === 'Detailpatients' ? true : false} onChange={formik.handleChange} value="Detailpatients" control={<Radio />} label="Detailansicht des Patienten" />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </div>

                      </div>
                    </Grid>
                    <Grid ClassName='mt-20px' item md={12}>
                      <h3 className='m-0px'>Daten für die DSGVO-Formulare</h3>
                      <h4 className='mb-5px mt-5px'>Datenschutzbeauftragter der Praxis</h4>
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="dgsvo_data_protection_officer_name"
                        value={formik.values.dgsvo_data_protection_officer_name}
                        onChange={formik.handleChange}
                        size="small"
                        label="Name"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="dgsvo_data_protection_officer_address"
                        value={formik.values.dgsvo_data_protection_officer_address}
                        onChange={formik.handleChange}
                        size="small"
                        label="Anschrift"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        style={{ width: "100%" }}
                        name="dgsvo_data_protection_officer_contact"
                        value={formik.values.dgsvo_data_protection_officer_contact}
                        onChange={formik.handleChange}
                        size="small"
                        label="Telefon / E-Mail"
                        id="outlined-basic"
                        variant="outlined"
                      // InputProps={props.editMode === false ? { readOnly: false } : { readOnly: true }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <h4 className='mb-5px mt-5px'>Externes Abrechnungsunternehmen</h4>
                      <TextField
                        style={{ width: "100%" }}
                        name="dgsvo_factoring_provide"
                        value={formik.values.dgsvo_factoring_provide}
                        onChange={formik.handleChange}
                        size="small"
                        label="Name & Anschrift"
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <h3 className='m-0px'>Danger Zone</h3>
                      <div className='danger-zone mt-5px'>
                        <span>Merken Sie sich Ihr neues Sperrpasswort gut.</span>
                      </div>
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        style={{ width: "100%" }}
                        name="dangerZonePassword"
                        value={formik.values.dangerZonePassword}
                        onChange={formik.handleChange}
                        size="small"
                        label="Sperrpasswort"
                        id="outlined-basic"
                        variant="outlined"
                      />
                      <p className='mt-5px fs-13px' style={{ color: '#6c757d' }}>
                        Nach dem erfolgreichen Ausfüllen eines Anamnesebogens muss ein Sperrpasswort eingegeben werden, um die Patientendaten vor unberechtigtem Zugriff zu schützen.
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose} style={{ borderRadius: "15px" }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={formik.handleSubmit} style={{ borderRadius: "15px" }}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
