import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddeditProfileModal from './AddeditProfile';
import MUIDataTable from "mui-datatables";
import { dynamicGetApi } from './../../Api';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Swal from 'sweetalert2';

const options = {
  filterType: 'checkbox',
  selectableRows: false,
  print:false,
  sortOrder: {
    name: 'email',
    direction: 'asc'
  }
};
export default function Profiles() {
  const [cookies, setCookie, removeCookie] = useCookies(['appLoginToken']);
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [recallList, setRecallList] = useState(false);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    apiCalls();
  }, []);
  useEffect(() => {
    if (recallList === true) {
      setTimeout(() => {
        apiCalls();
        setRecallList(false);
      }, 100);
    }
  }, [recallList]);
  const apiCalls = async () => {
    let url = 'external/profile/getProfileList';
    let res = await dynamicGetApi(url, navigate, setCookie);
    setData(res.data);
  }

  const deleteProfile = (e) => {
    let id = e.currentTarget.id;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMainProfile(id)
        Swal.fire(
          'Deleted!',
          'Record has been deleted.',
          'success'
        )
      }
    })
  }
  const deleteMainProfile = async (e) => {
    let url = 'external/profile/deleteProfile?id=' + e;
    let res = await dynamicGetApi(url, navigate, setCookie);
    apiCalls();
    setRecallList(false);
  }
  const editProfile = async (e) => {
    setSelectId(e.currentTarget.id)
    setShow(true);
    setEditMode(true);
  }
  const columns = [
    { name: "salutation", label: "Anrede" },
    { name: "firstName", label: "First Name" },
    { name: "lastName", label: "Last Name" },
    { name: "profile_Email", label: "Profile Email" },
    {
      label: "Actions",
      name: "id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button variant="contained" color="primary" id={value} onClick={(e) => editProfile(e)} style={{ marginRight: "5px" }}>
                <EditIcon />
              </Button>
              <Button variant="contained" color="primary" id={value} onClick={(e) => deleteProfile(e)}>
                <DeleteIcon />
              </Button>
            </>
          );
        }
      }
    }
  ];
  return (
    <>
      {
        show === true ?
          <AddeditProfileModal show={show} setShow={setShow} selectId={selectId} setRecallList={setRecallList} editMode={editMode} setEditMode={setEditMode} /> : null
      }
      <Box sx={{ width: '100%' }}>
        <div className='text-right mb-3'>
          <Button variant="contained" color="primary" onClick={() => setShow(true)} id={"Add"} >
            <PersonAddIcon />
          </Button>
        </div>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <div style={{ height: 600, width: '100%' }}>
            <MUIDataTable
              title={"Profile List"}
              data={data}
              columns={columns}
              options={options}
            />
          </div>
        </Paper>
      </Box>
    </>
  );
}
