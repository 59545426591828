import axios from "axios";
import React, { useContext } from 'react';
import { SERVER_URL } from './Config';
import Swal from 'sweetalert2';
import { setUserName } from './Redux/Slice/userSlice';

export async function dynamicPostApi(data, Url, useNavigate, setCookie) {
  //setCookie("isLoading", true);
  const token = getCookie("appToken");
  return await axios({
    method: "Post",
    url: SERVER_URL + Url,
    headers: {
      Authorization: "Bearer " + token,
    },
    data: data
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (!!error.response) {
        if (error.response.status === 401) {
          useNavigate("/login");
        }
        else if (error.response.status === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong...',
          })
        }
      }
    })
    .finally(() => {
      // setTimeout(() => {
      //   setCookie("isLoading", false);
      // }, 1000);
    });
}

export async function dynamicGetApi(Url, useNavigate, setCookie) {
  const token = getCookie("appToken");
  //setCookie("isLoading", true);
  return await axios({
    method: "Get",
    url: SERVER_URL + Url,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {

      if (error.response.status === 401) {
        useNavigate("/login");
      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        })
      }
    })
    .finally(() => {
      // setTimeout(() => {
      //   setCookie("isLoading", false);
      // }, 1000);
    });
}

export async function loginApi(data, usecookie, useNavigate, setCookie, dispatch) {
  //setCookie("isLoading", true);
  return await axios({
    method: "Post",
    url: SERVER_URL + "api/account/adminLogin",
    data: data
  })
    .then((response) => {
      if (response.status === 200) {
        usecookie('appToken', response.data.token, { path: '/' });
        useNavigate("/");
        dispatch(setUserName(response.data.userName));
      }
      return response;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        useNavigate("/login");
      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        })
      }
      else if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Incorrect username or password.',
        })
      }
    })
    .finally(() => {
      // setTimeout(() => {
      //   setCookie("isLoading", false);
      // }, 1000);
    });
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function AppLogout(removeCookie, useNavigate, setCookie) {
  removeCookie("appToken");
  useNavigate("/login");
}