import { React, useEffect, useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { loginApi } from './../../Api';
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignIn() {

    const [cookies, setCookie, removeCookie] = useCookies(['appLoginToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let logBtn = useRef();

    const formik = useFormik({
        initialValues: {
            password: '',
            userName: ''
        },
        validationSchema: Yup.object().shape({
            userName: Yup.string().min(5, 'Too Short!').email("Invalid email address").required("Please enter email"),
            password: Yup.string().min(6, 'Too Short!').required("Please enter Password")
        }),
        onSubmit: values => {
            loginApi(values, setCookie, navigate, setCookie, dispatch);
        },
    });
    useEffect(() => {
        onEnter();
    }, []);
    const onEnter = () => {
        document.addEventListener("keydown", function (event) {
            if (event.keyCode === 13) {
                logBtn.current.click();
            }
        })
    }


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Log In
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <TextField
                            error={formik.errors.userName && formik.touched.userName ? true : false}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="userName"
                            autoComplete="email"
                            helperText={formik.errors.userName && formik.touched.userName ? formik.errors.userName : ""}
                            autoFocus
                            value={formik.values.userName}
                            onChange={formik.handleChange}
                        />
                        <TextField
                            error={formik.errors.password && formik.touched.password ? true : false}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            helperText={formik.errors.password && formik.touched.password ? formik.errors.password : ""}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            ref={logBtn}
                            onClick={formik.handleSubmit}
                        >
                            Log In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}